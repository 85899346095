import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardTitle,{staticClass:"justify-center mb-4"},[_c(VSpacer),_c('span',[_vm._v(" Realocar proposta ")]),_c(VSpacer),_c(VIcon,{on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c(VCardText,[_c('separator',{attrs:{"label":"Realocar"}}),_c('div',{staticClass:"container-info"},[_c('div',{staticClass:"container-icon"},[_c(VIcon,{attrs:{"size":"20","color":"white"}},[_vm._v(" "+_vm._s(_vm.icons.mdiInformationVariant)+" ")])],1),_c('div',{staticClass:"container-text"},[_c('span',[_vm._v("A ação deve ser aplicada somente quando houver parcelas pagas pelo cliente após o vencimento e que não foram contabilizadas na listagem de vendas do mês de referência do vendedor.")])])]),_c('p',{staticClass:"mt-4"},[_vm._v(" Insira o número da proposta "),_c('strong',{staticStyle:{"color":"rgba(239, 112, 255, 1)"}},[_vm._v(" anterior ")])]),_c(VTextField,{attrs:{"label":"N° da proprosta","outlined":"","dense":"","md":"2"},model:{value:(_vm.proposal),callback:function ($$v) {_vm.proposal=$$v},expression:"proposal"}}),_c('div',{staticClass:"actions-filter"},[_c(VBtn,{staticClass:"ml-5",attrs:{"color":"info"},on:{"click":function($event){return _vm.handleRelocate()}}},[(!_vm.isLoading)?_c('div',{staticClass:"d-flex items-center"},[_c('span',{staticClass:"mr-2"},[_vm._v("REALOCAR")]),_c(VIcon,{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiRestore)+" ")])],1):_c(VProgressCircular,{attrs:{"color":"white","indeterminate":""}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }