<template>
  <div>
    <v-card>
      <v-card-title class="justify-center mb-4">
        <v-spacer />

        <span>
          Realocar proposta
        </span>

        <v-spacer />

        <v-icon
          @click="closeModal()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>

      <v-card-text>
        <separator label="Realocar" />

        <div class="container-info">
          <div class="container-icon">
            <v-icon
              size="20"
              class=""
              color="white"
            >
              {{ icons.mdiInformationVariant }}
            </v-icon>
          </div>

          <div class="container-text">
            <span>A ação deve ser aplicada somente quando houver parcelas pagas pelo cliente após o vencimento e que não foram contabilizadas na listagem de vendas do mês de referência do vendedor.</span>
          </div>
        </div>

        <p class="mt-4">
          Insira o número da proposta
          <strong style="color: rgba(239, 112, 255, 1)">
            anterior
          </strong>
        </p>

        <v-text-field
          v-model="proposal"
          label="N° da proprosta"
          outlined
          dense
          class=""
          md="2"
        />

        <div class="actions-filter">
          <v-btn
            color="info"
            class="ml-5"
            @click="handleRelocate()"
          >
            <div
              v-if="!isLoading"
              class="d-flex items-center"
            >
              <span class="mr-2">REALOCAR</span>
              <v-icon size="20">
                {{ icons.mdiRestore }}
              </v-icon>
            </div>
            <v-progress-circular
              v-else
              color="white"
              indeterminate
            >
            </v-progress-circular>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'
import {
  mdiClose, mdiFilterVariantRemove, mdiInformationVariant, mdiRestore,
} from '@mdi/js'
import localStorageSlim from 'localstorage-slim'

export default {
  components: { Separator },
  mixins: [formatters, messages, utilsMixin],

  data() {
    return {
      employee: '',
      proposal: '',
      icons: {
        mdiClose,
        mdiFilterVariantRemove,
        mdiInformationVariant,
        mdiRestore,
      },
      listRegionsItems: ['IMPERATRIZ', 'AÇAILÂNDIA', 'GRAJAÚ'],
      store: '',
      listStore: [],
      monthReference: false,
      monthRefeReferenceValue: '',
      isLoading: false,
      listEmployees: [],
    }
  },
  computed: {
    computedMonthReference() {
      return this.dateFormat(this.monthRefeReferenceValue)
    },
  },

  created() {
    this.getListStore()

    const employees = localStorageSlim.get('listEmployees', { decrypt: true })

    this.listEmployees = employees
  },
  methods: {
    async getListStore() {
      try {
        const response = await axiosIns.get('/api/v1/records/company/index')

        this.listStore = response.data.data
      } catch (error) {
        this.showMessage({
          title: 'Erro ao buscar a lista de lojas!',
          text: error,
          icon: 'error',
        })
      }
    },

    async handleRelocate() {
      this.isLoading = true
      try {
        const body = {
          proposal_number: this.proposal,
        }

        await axiosIns.post('/api/v1/integration/contract/adjust_compliance', body)

        this.showMessage({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Proposta realocada com sucesso!',
          timer: 2500,
        })

        this.closeModal()
      } catch (error) {
        console.log({ error })

        this.showMessage({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao realocar dados!',
          timer: 2500,
        })
      } finally {
        this.isLoading = false
      }
    },

    sendFilteredData(data, body) {
      this.$emit('filteredData', data)
      this.$emit('filteredBody', body)
    },
  },
}
</script>

<style scoped>
  .datePicker {
  width: 100%;
}

.vacation{
  display: flex;
  align-items: center;
}

.vacation p{
  margin-top: 12px;
}

.actions-filter{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.container-info{
  width: 100%;
  background: #1E1E1E4D;
  border-radius: 5px;
  margin-top: 30px;
  padding: 10px;

  display: flex;
  align-items: center;
  gap: 10px;
}
.container-icon{
  background: #16B1FF;
  border-radius: 5px;
  height: 30px;
  width: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.container-text{
  width: 90%;
  font-size: 13px;
  text-align: justify;
}
</style>
