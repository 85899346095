<template>
  <div>
    <v-card class="pl-4 pt-4 pb-4 pr-4">
      <div class="d-flex">
        <span
          class="mt-2 ml-4"
          style="font-size: 18px;"
        >
          Adimplência
        </span>

        <v-spacer />

        <v-text-field
          v-model="filterData"
          class="mr-2"
          label="Buscar por nome"
          outlined
          dense
          @keyup.enter="findFilterResult()"
        />

        <v-btn
          :color="itemsTable.length > 0 ? 'success' : 'gray'"
          class="me-3"
          outlined
          @click="openModalFilter()"
        >
          <v-icon
            size="20"
            class="me-1"
          >
            {{ icons.mdiFilterVariant }}
          </v-icon>
          <span>
            FILTRO
          </span>
        </v-btn>

        <v-btn
          v-show="itemsTable.length > 0"
          class="mr-4"
          color="error"
          outlined
          @click="clearGrid"
        >
          <v-icon size="30">
            {{ icons.mdiFilterVariantRemove }}
          </v-icon>

          <span class="ml-2">Limpar</span>
        </v-btn>

        <v-btn
          class="mr-4"
          color="gray"
          outlined
          @click="openModalRelocateAction()"
        >
          <span class="mr-2">REALOCAR PROPOSTA</span>
          <v-icon size="20">
            {{ icons.mdiRestore }}
          </v-icon>
        </v-btn>
      </div>

      <v-data-table
        :items="listOfFilteredItems"
        :headers="headers"
      >
      </v-data-table>

      <v-dialog
        v-model="openModal"
        max-width="500px"
        persistent
      >
        <ModalFilter
          @close="openModal = false"
          @filteredData="$event => setTableData($event)"
          @filteredBody="$event => setBodyFilter($event)"
        ></ModalFilter>
      </v-dialog>

      <v-dialog
        v-model="openModalRelocate"
        max-width="500px"
        persistent
      >
        <ModalRelocate
          @close="openModalRelocate = false"
        ></ModalRelocate>
      </v-dialog>
    </v-card>
  </div>
</template>
<script>
import {
  mdiAccountOutline, mdiAccountStarOutline, mdiAccountTieOutline,
  mdiDockWindow,
  mdiFilterVariant,
  mdiFilterVariantRemove,
  mdiRestore,
  mdiTagArrowUpOutline,
} from '@mdi/js'

import ModalFilter from './ModalFilter.vue'
import ModalRelocate from './ModalRelocate.vue'

export default {
  components: {
    ModalFilter,
    ModalRelocate,
  },
  data() {
    return {
      openModalRelocate: false,
      itemsTable: [],
      listOfFilteredItems: [],
      headers: [
        { text: 'FILIAL', value: 'region', align: '' },
        { text: 'LOJA', value: 'fantasy_name', align: '' },
        { text: 'FUNCIONÁRIO', value: 'name', align: 'center' },
        { text: 'CPF', value: 'cpf', align: 'center' },
        { text: 'NÍVEL', value: 'level', align: '' },
        { text: 'META', value: 'goal', align: 'center' },
        { text: 'REALIZADO', value: 'sales', align: 'center' },
        { text: 'ADIMPLÊNCIA', value: 'percentage', align: 'center' },
      ],
      filterData: '',
      openModal: false,
      icons: {
        mdiAccountOutline,
        mdiAccountTieOutline,
        mdiAccountStarOutline,
        mdiTagArrowUpOutline,
        mdiDockWindow,
        mdiFilterVariantRemove,
        mdiFilterVariant,
        mdiRestore,
      },
    }
  },

  watch: {
    filterData() {
      this.findFilterResult()
    },
  },

  methods: {
    findFilterResult() {
      const { itemsTable, filterData } = this
      const filterDataLower = filterData.toLowerCase()

      this.listOfFilteredItems = itemsTable.filter(({ name }) => {
        const employee = name.toLowerCase()

        return employee.includes(filterDataLower)
      })
    },

    clearGrid() {
      this.listOfFilteredItems = []
      this.itemsTable = []
    },
    setTableData(data) {
      this.listOfFilteredItems = data
      this.itemsTable = data
    },
    setBodyFilter(body) {
      this.bodyFilter = body
    },
    openModalFilter() {
      this.openModal = true
    },
    openModalRelocateAction() {
      this.openModalRelocate = true
    },
  },

}
</script>
<style scoped>
.separator{
  position: relative;
  border-top: 1px solid #cac9cd96;
  margin-left: 20px;
}

.container-actions{
  display: flex;
  align-items: center;
}

.container-actions input {
  padding: 12px 28px;
  width: 90px;
  border-radius: 5px;
  border: 0.5px solid var(--primrias-opacidade-50, rgba(231, 227, 252, 0.50));
  outline: none;
  color: #E7E3FC;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-inputs{
  display: flex;
  max-width: 800px;
}

.section-inputs .container-commisions{
  width: 350px;
  margin: 12px 20px 0px 20px;
}

.container-bonnification{
  width: 450px;
  background: #ccc;
}

.toggle-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 6px 0px;

  width: 200px;
}

.toggle-btn span{
  font-size: 14px;
  color: #fff
}

.toggle-btn-active{
  background: #16B1FF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 6px 0px;

  width: 200px;
}

.toggle-btn-active span{
  font-size: 14px;
  color: #fff
}

.container-sections{
  margin: 25px 25px;
}
</style>
